import { useEffect } from 'react';
import AOS from 'aos';
import { initializeChatbot } from './src/hooks/useFacebookChatbot';

import 'aos/dist/aos.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'semantic-ui-css/semantic.min.css';
import './src/style.css';
import { initializeMixpanel, trackMixpanelLinks, trackMixpanelPageView } from './src/utils/mixpanelUtils';

export const wrapPageElement = () => {
  initializeChatbot();

  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);

  const getAnchorLinkData = (ele: any) => ({
    'Link Text': ele?.textContent,
    Section: ele?.dataset?.section || '',
  });

  useEffect(() => {
    trackMixpanelLinks('a', 'Link clicked', getAnchorLinkData);
  }, []);
};

export const onClientEntry = () => {
  // Mixpanel
  initializeMixpanel();
};

export const onRouteUpdate = ({ prevLocation }: { prevLocation: any }) => {
  trackMixpanelPageView(!prevLocation);
};
