exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-partner-program-tsx": () => import("./../../../src/pages/partner-program.tsx" /* webpackChunkName: "component---src-pages-partner-program-tsx" */),
  "component---src-pages-platform-compensation-and-payroll-tsx": () => import("./../../../src/pages/platform/compensation-and-payroll.tsx" /* webpackChunkName: "component---src-pages-platform-compensation-and-payroll-tsx" */),
  "component---src-pages-platform-culture-tsx": () => import("./../../../src/pages/platform/culture.tsx" /* webpackChunkName: "component---src-pages-platform-culture-tsx" */),
  "component---src-pages-platform-organization-management-tsx": () => import("./../../../src/pages/platform/organization-management.tsx" /* webpackChunkName: "component---src-pages-platform-organization-management-tsx" */),
  "component---src-pages-platform-people-management-tsx": () => import("./../../../src/pages/platform/people-management.tsx" /* webpackChunkName: "component---src-pages-platform-people-management-tsx" */),
  "component---src-pages-platform-recruitment-and-onboarding-tsx": () => import("./../../../src/pages/platform/recruitment-and-onboarding.tsx" /* webpackChunkName: "component---src-pages-platform-recruitment-and-onboarding-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refund-policy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-why-sofflix-hr-tsx": () => import("./../../../src/pages/why-sofflix-hr.tsx" /* webpackChunkName: "component---src-pages-why-sofflix-hr-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */)
}

