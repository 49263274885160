import React, { useEffect } from 'react';

export const initializeChatbot = () => {
  const windowObj = typeof window !== 'undefined' ? (window as any) : null;
  useEffect(() => {
    if (windowObj) {
      windowObj.fbAsyncInit = function () {
        windowObj.FB?.init({
          xfbml: true,
          version: 'v16.0',
        });
      };

      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js: any = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs?.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }, []);

  useEffect(() => {
    const chatBox = document.getElementById('fb-customer-chat');
    if (chatBox) {
      chatBox.setAttribute('page_id', '110295550870502');
      chatBox.setAttribute('attribution', 'biz_inbox');
    }
  }, []);
};

export const FacebookChatbot = () => (
  <>
    <div id="fb-root" />
    <div id="fb-customer-chat" className="fb-customerchat" />
  </>
);
