import mixpanel from 'mixpanel-browser';

export const initializeMixpanel = () => {
  if (!process.env.GATSBY_MIXPANEL_API_TOKEN) {
    return;
  }
  mixpanel.init(process.env.GATSBY_MIXPANEL_API_TOKEN, {
    track_links_timeout: 1000,
  });

  // Add below with each event
  mixpanel.register({
    Environment: process.env.GATSBY_ACTIVE_ENV,
  });
};

export const trackMixpanelPageView = (isEntryPage: boolean) => {
  if (!process.env.GATSBY_MIXPANEL_API_TOKEN) {
    return;
  }
  mixpanel.track_pageview({ 'Is Entry Page': !!isEntryPage });
};

export const trackMixpanelEvent = (eventName: string, properties: any = null) => {
  if (!process.env.GATSBY_MIXPANEL_API_TOKEN) {
    return;
  }
  if (properties) {
    mixpanel.track(eventName, properties);
  } else {
    mixpanel.track(eventName);
  }
};
export const trackMixpanelEventWithCallback = (eventName: string, properties = null, callback = null) => {
  if (!process.env.GATSBY_MIXPANEL_API_TOKEN) {
    return;
  }
  mixpanel.track(eventName, properties, { send_immediately: true }, callback);
};

export const trackMixpanelLinks = (query: string, eventName: string, properties: any = null) => {
  if (!process.env.GATSBY_MIXPANEL_API_TOKEN) {
    return;
  }
  mixpanel.track_links(query, eventName, properties);
};
